<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-11 col-md-11 nopadd-right sort-control">
        Filter By
        <div class="filters">
          <select v-model="status">
            <option value>All</option>
            <option value="pending">Pending</option>
            <option value="referred">Referred</option>
            <option value="account-created">Account Created</option>
          </select>
          <button
            class="btn-positive btn-send-message pull-right"
            data-toggle="modal"
            data-target="#send-message-popup"
            v-if="userRole=='super-admin'"
          >Send Message</button>
        </div>
      </div>
    </div>
    <SendMessagePopup
      popupId="send-message-popup"
      :checkedUsersList="checkedUsersArr"
      @messageSent="messageSent"
      :selectAllPicklrs="selectAllPicklrs"
      :picklrFilters="picklrFilters"
    />
    <!-- </div> -->
    <div class="send-msg-check" v-if="userRole=='super-admin'">
      <input type="checkbox" :checked="selectAllPicklrs"  @change="checkAllPicklrs($event)"><label>Select All Referrals</label>
    </div>
    <div v-if="picklrs.length > 0">
      <UserReferralItemVue
        v-for="picklr in picklrs"
        :key="picklr.id"
        :picklr="picklr"
        :selectedPicklrs="checkedUsersArr"
        @updateCheckedUsers="checkedUsersList"
        :selectAllPicklrs="selectAllPicklrs"
      />
    </div>
    <h2
      v-if="picklrs.length === 0 && !loadingPicklrs"
      style="text-align:center;"
    >No Referred users Found in system!</h2>
    <h2 v-if="loadingPicklrs" style="text-align:center;">Loading Referrals...</h2>
    <div class="text-center" v-if="hasMorePicklrs && !loadingPicklrs" @click="getPicklrs">
      <button class="btn-positive">Load more</button>
    </div>
  </div>
</template>

<script>

import SendMessagePopup from '@/components/SAdmin/SendMessagePopup.vue'
import UserReferralItemVue from '@/components/SAdmin/UserReferralItem.vue'

export default {
  name: 'Picklr',
  data () {
    return {
      loadingPicklrs: true,
      hasMorePicklrs: false,
      status: this.$route.query['status'] || '',
      rowsLimitPerRequest: 100,
      rowsOffset: 0,
      picklrs: [],
      sort: this.$route.query['sortBy'] || 'created_at-DESC',
      mobileNumber: '',
      checkedUsersArr: [],
      userRole: 'super-admin',
      states: [],
      selectAllPicklrs: false,
      lat: this.$route.query['lat'] || '',
      lng: this.$route.query['lng'] || '',
      picklrFilters: {},
      countries: []
    }
  },
  components: {
    UserReferralItemVue,
    SendMessagePopup
  },

  created () {
    this.$store.dispatch('getAppSettingsKeyValuePair')
  },
  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    this.getPicklrs()
  },
  methods: {

    checkAllPicklrs (e) {
      if (e.target.checked) {
        this.selectAllPicklrs = true
      } else {
        this.selectAllPicklrs = false
      }
    },

    messageSent () {
      Object.assign(this.$data, (this.$options.data).apply(this))
      this.reFilter()
      this.checkedUsersArr = []
    },

    checkedUsersList (selectedUsers, status) {
      const found = this.checkedUsersArr.indexOf(selectedUsers)
      if (status === 'added') {
        this.checkedUsersArr.push(selectedUsers)
      } else {
        this.checkedUsersArr.splice(found, 1)
      }
    },
    getPicklrs () {
      this.loadingPicklrs = true
      let q = { status: this.status, limit: this.rowsLimitPerRequest, offset: this.rowsOffset, sort: this.sort }
      this.picklrFilters = q
      this.$http
        .get('user_referrals', { params: q })
        .then(
          function (response) {
            if (this.rowsLimitPerRequest === q.limit && this.rowsOffset === q.offset) {
              this.rowsOffset += this.rowsLimitPerRequest
              this.picklrs.push(...response.body.data)
              this.selectAllPicklrs = false
              if (this.picklrs.length === response.body.count) {
                this.hasMorePicklrs = false
              } else {
                this.hasMorePicklrs = true
              }
              this.loadingPicklrs = false
            }
          }
        )
    },
    reFilter () {
      this.picklrs = []
      this.rowsOffset = 0
      this.getPicklrs()
      this.$router.push({ query: { sortBy: this.sort, status: this.status } })
    }
  },
  watch: {
    status () {
      this.$common.delay(() => {
        this.reFilter()
      }, 500)()
    }
  }
}
</script>

<style scoped>
.sort-control {
  margin-bottom: 8px;
}

.sort-control .btn-select,
.sort-control select {
  margin-bottom: 7px;
}

div.dropdown .btn-select + ul.dropdown-menu {
  top: calc(100% - 7px);
}

.filters {
  display: inline-block;
  width: calc(100% - 66px);
  vertical-align: text-top;
}

@media only screen and (max-width: 767px) {
  .sort-control {
    text-align: left;
    padding-left: 15px;
  }
}
.btn-send-message {
  margin-left: 3%;
}
</style>
